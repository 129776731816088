export const minutesToHours = (minutes: number) => {
    if (typeof minutes !== 'number' || minutes < 0) {
        return ''
    }

    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    const hoursText = hours > 0 ? `${hours}h` : ''
    const minutesText = remainingMinutes > 0 ? `${remainingMinutes}min` : ''

    return hoursText + (hoursText && minutesText ? ' ' : '') + minutesText
}

export const getInitials = (name: string) => {
    const nameArray = name.split(' ')

    const [firstName, lastName] = [
        nameArray[0],
        nameArray[nameArray.length - 1],
    ]

    return nameArray.length > 1 ? `${firstName[0]}${lastName[0]}` : firstName[0]
}

export const percentageFormat = (percentage: number, decimals = 2): string => {
    return Intl.NumberFormat('pt-br', {
        style: 'percent',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(percentage / 100)
}

export const numberFormat = (value: number | string, decimals = 2) => {
    const number = typeof value === 'string' ? Number(value) : value

    const newValue = Intl.NumberFormat('pt-br', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(number)

    return newValue
}

export const cpfMask = (value: string) => {
    return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

import { isAxiosError } from '@/api/business'
import TopMessage from '@/components/TopMessage'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Skeleton } from '@/components/ui/skeleton'
import { TypographyP } from '@/components/ui/typography'
import { useUserSystemsQuery } from '@/queries/useUserSystemsQuery'
import { useParams } from 'react-router-dom'
import SettingsDropdown from './components/SettingsDropdown'
import Image from '@/components/Image'
import getAppIcon from '@/utils/getAppIcon'
import { Input } from '@/components/ui/input'
import { Search } from 'lucide-react'
import { useEffect, useState } from 'react'
import { System } from '@/api/business/systems/types'
import { useUserQuery } from '@/queries/useUsersQuery'

const UserSystems = () => {
    const { user: userParam } = useParams()

    const { data: users } = useUserQuery()

    const user = users?.find((user) => user.user === userParam)

    const {
        data = [],
        isPending,
        isSuccess,
        isError,
        error,
    } = useUserSystemsQuery({ userId: Number(user!.userId), enabled: !!user })

    const [filteredData, setFilteredData] = useState(data)
    const [inputValue, setInputValue] = useState('')

    const onFilter = (data: System[], value: string) => {
        return data.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        )
    }

    const onInputChange = (value: string) => {
        setInputValue(value)
        setFilteredData(onFilter(data, value))
    }

    useEffect(() => {
        if (filteredData.length === 0 && isSuccess) {
            setFilteredData(data)
        }
    }, [data])

    return (
        <div className="flex flex-col space-y-4 overflow-hidden">
            <div className="flex items-center justify-between">
                <TypographyP className="font-semibold">
                    Sistemas do usuário - ({data.length})
                </TypographyP>
                <SettingsDropdown />
            </div>
            <div>
                <div className="flex items-center gap-2 px-3 max-w-[400px] border rounded-md">
                    <Search size={14} />
                    <Input
                        className="border-none shadow-none outline-none focus-visible:ring-0"
                        placeholder="Buscar por sistema"
                        onChange={(e) => onInputChange(e.target.value)}
                        value={inputValue}
                    />
                </div>
            </div>
            {isError && (
                <TopMessage
                    variant="error"
                    text={
                        isAxiosError(error)
                            ? error.response?.data.error ||
                              'Erro ao carregar sistemas'
                            : 'Erro ao carregar sistemas'
                    }
                />
            )}
            {isPending && (
                <div className="space-y-4">
                    <Skeleton className="w-full h-14" />
                    <Skeleton className="w-full h-14" />
                    <Skeleton className="w-full h-14" />
                    <Skeleton className="w-full h-14" />
                </div>
            )}
            {!isPending && data.length === 0 && (
                <TypographyP>
                    Usuário não tem acesso à nenhum sistema
                </TypographyP>
            )}
            {isSuccess && data.length > 0 ? (
                <ScrollArea className="flex-1">
                    {filteredData.map((system) => {
                        const image = getAppIcon(system.name)
                        const fallback = getAppIcon('default')

                        return (
                            <div
                                key={system.systemId}
                                className="flex items-center gap-4 p-4 mb-4 transition duration-300 ease-in border rounded-md hover:bg-accent"
                            >
                                <Image
                                    className="w-[24px]"
                                    src={image || fallback}
                                    fallbackSrc={fallback}
                                />
                                <TypographyP>{system.name}</TypographyP>
                            </div>
                        )
                    })}
                </ScrollArea>
            ) : null}
        </div>
    )
}

export default UserSystems

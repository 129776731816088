import { fetchUsers } from '@/api/business/users'
import { useQuery } from '@tanstack/react-query'

export const userKeys = {
    all: ['user'] as const,
    lists: () => [...userKeys.all, 'list'] as const,
}

export const useUserQuery = () => {
    return useQuery({
        queryFn: () => fetchUsers(),
        queryKey: userKeys.lists(),
    })
}

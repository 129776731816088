import { Skeleton } from '@/components/ui/skeleton'

const CardsSkeleton = () => {
    const items = Array.from({ length: 5 }, (_, i) => i)

    return items.map((item) => {
        return (
            <div
                key={item}
                className="flex flex-col items-center justify-center gap-3 p-4 shadow bg-card"
            >
                <div className="flex flex-col items-center justify-center gap-4">
                    <Skeleton className="w-[56px] h-[56px]" />
                    <Skeleton className="w-[90px] h-[20px]" />
                </div>
                <div className="flex flex-col items-center justify-center gap-1">
                    <Skeleton className="w-[120px] h-[18px]" />
                    <Skeleton className="w-[160px] h-[18px]" />
                    <Skeleton className="w-[120px] h-[18px]" />
                </div>
            </div>
        )
    })
}

export default CardsSkeleton

// import QuickActions from './components/QuickActions'
import MainContent from './components/MainContent'

const Home = () => {
    return (
        <div className="flex h-full overflow-auto">
            <div className="container h-full ">
                <MainContent />
            </div>
            {/* <QuickActions /> */}
        </div>
    )
}

export default Home

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import Login from '@/pages/Login'
import NotFound from '@/pages/NotFound'
import PublicLayout from '@/pages/Layouts/PublicLayout'
import ResetPassword from '@/pages/ResetPassword'
import { ROUTES } from './consts/routes'
import MainLayout from './pages/Layouts/MainLayout'
import Home from './pages/Home'
import RequireAuth from './middlewares/RequireAuth'
import Users from './pages/Users'
import UserDetail from './pages/UserDetail'
import UserData from './pages/UserData'
import UserSystems from './pages/UserSystems'
import UserPassword from './pages/UserPassword'
import CreateUser from './pages/CreateUser'

const router = createBrowserRouter([
    {
        element: <MainLayout />,
        children: [
            {
                path: ROUTES.HOME.index,
                element: (
                    <RequireAuth isAllowed>
                        <Home />
                    </RequireAuth>
                ),
            },
            {
                path: ROUTES.USERS.index,
                children: [
                    {
                        index: true,
                        element: (
                            <RequireAuth isAllowed>
                                <Users />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.CREATE_USER.index,
                        element: (
                            <RequireAuth isAllowed>
                                <CreateUser />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ':user',
                        element: (
                            <RequireAuth isAllowed>
                                <UserDetail />
                            </RequireAuth>
                        ),
                        children: [
                            {
                                index: true,
                                element: (
                                    <Navigate to={ROUTES.USER_DATA.index} />
                                ),
                            },
                            {
                                path: ROUTES.USER_DATA.index,
                                element: (
                                    <RequireAuth isAllowed>
                                        <UserData />
                                    </RequireAuth>
                                ),
                            },
                            {
                                path: ROUTES.USER_SYSTEMS.index,
                                element: (
                                    <RequireAuth isAllowed>
                                        <UserSystems />
                                    </RequireAuth>
                                ),
                            },
                            {
                                path: ROUTES.USER_PASSWORD.index,
                                element: (
                                    <RequireAuth isAllowed>
                                        <UserPassword />
                                    </RequireAuth>
                                ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        element: <PublicLayout />,
        children: [
            {
                path: ROUTES.NOT_FOUND.index,
                element: <NotFound />,
            },
            {
                path: ROUTES.LOGIN.index,
                element: <Login />,
            },
            {
                path: ROUTES.RECOVER_PASSWORD.index,
                element: <ResetPassword />,
            },
        ],
    },
])

const Router = () => {
    return <RouterProvider router={router} />
}

export default Router

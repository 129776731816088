import { createWithEqualityFn } from 'zustand/traditional'
import { immer } from 'zustand/middleware/immer'
import { shallow } from 'zustand/shallow'
import { useAuthSlice } from './authSlice'
import { useResetSlice } from './resetSlice'
import { useAppConfigSlice } from './appConfigSlice'
import { Store } from './type'

export interface NormalizedState<T> {
    ids: (string | number)[]
    entities: { [id: string]: T }
}

export const useBaseStore = createWithEqualityFn<Store>()(
    immer((...a) => ({
        authSlice: useAuthSlice(...a),
        resetSlice: useResetSlice(...a),
        appConfigSlice: useAppConfigSlice(...a),
    })),
    shallow
)

import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Copy, Settings } from 'lucide-react'
import ReplicateDialog from '../ReplicateDialog'
import useDisclosure from '@/hooks/useDisclosure'

const SettingsDropdown = () => {
    const {
        isOpen: isReplicateDialogOpen,
        onOpen: onReplicateDialogOpen,
        onClose: onReplicateDialogClose,
    } = useDisclosure()

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <Button variant="ghost" size="icon">
                        <Settings size={14} />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuGroup>
                        <DropdownMenuItem
                            className="gap-2"
                            onClick={onReplicateDialogOpen}
                        >
                            <Copy size={14} />
                            Replicar acessos
                        </DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
            <ReplicateDialog
                isOpen={isReplicateDialogOpen}
                onClose={onReplicateDialogClose}
            />
        </>
    )
}

export default SettingsDropdown

import { isAxiosError } from '@/api/business'
import { resetUserPassword } from '@/api/business/users'
import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { useUserQuery } from '@/queries/useUsersQuery'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'

type ConfirmDialogProps = {
    isOpen: boolean
    setNewPassword: (password: string) => void
    onClose: () => void
    onPasswordDialogOpen: () => void
}

const ConfirmDialog = ({
    isOpen,
    setNewPassword,
    onPasswordDialogOpen,
    onClose,
}: ConfirmDialogProps) => {
    const { user: userParam } = useParams()

    const { data: users } = useUserQuery()

    const user = users?.find((user) => user.user === userParam)

    const { mutate, isPending } = useMutation({
        mutationFn: resetUserPassword,
        onSuccess: (data) => {
            setNewPassword(data.password)
            onPasswordDialogOpen()
            onClose()
            toast('Senha resetada com sucesso')
        },
        onError: (err) => {
            let message = 'Houve um erro ao resetar a senha. Tente novamente.'

            if (isAxiosError(err)) {
                message = err.response?.data.error || message
            }

            toast(message)
        },
    })

    const onConfirm = () => {
        mutate({ userId: user!.userId })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Resetar senha</DialogTitle>
                </DialogHeader>
                <div>
                    <TypographyP>
                        Realmente deseja resetar a senha do usuário?
                    </TypographyP>
                </div>
                <DialogFooter>
                    <Button onClick={onClose} variant="ghost">
                        Cancelar
                    </Button>
                    <Button onClick={onConfirm} disabled={isPending}>
                        Confirmar
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialog

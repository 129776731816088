import { MultiSelect } from '@/components/MultiSelect'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import useDisclosure from '@/hooks/useDisclosure'
import { useUserQuery } from '@/queries/useUsersQuery'
import { useEffect, useState } from 'react'
import UserSystems from './components/UserSystems'
import { User } from '@/api/business/users/types'
import { Label } from '@/components/ui/label'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { replicateUserAccess } from '@/api/business/users'
import { userSystemsKeys } from '@/queries/useUserSystemsQuery'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { isAxiosError } from '@/api/business'

type ReplicateDialogProps = {
    isOpen: boolean
    onClose: () => void
}

const ReplicateDialog = ({ isOpen, onClose }: ReplicateDialogProps) => {
    const { user: userParam } = useParams()

    const { data: users } = useUserQuery()

    const user = users?.find((user) => user.user === userParam)

    const [selection, setSelection] = useState<Record<string, boolean>>({})
    const [systemsSelection, setSystemsSelection] = useState<
        Record<string, boolean>
    >({})

    const queryClient = useQueryClient()

    const {
        isOpen: isMultiSelectOpen,
        onOpen: onMultiSelectOpen,
        onClose: onMultiSelectClose,
    } = useDisclosure()

    const {
        data = [],
        isPending: isUsersPending,
        isError: isUsersError,
    } = useUserQuery()

    const { mutate, isPending: isReplicatePending } = useMutation({
        mutationFn: replicateUserAccess,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: userSystemsKeys.detail(Number(user!.userId)),
            })
            toast.success('Acesso replicado com sucesso')
            setSelection({})
            onClose()
        },
        onError: (err) => {
            let message = 'Houve um erro ao replicar acessos'

            if (isAxiosError(err)) {
                message = err.response?.data?.error || message
            }

            toast.error(message)
        },
    })

    const getId = (user: User) =>
        `${user.userId}${user.registration}${user.name}`.toLowerCase()

    const userSelected = data.find((user) => selection[getId(user)])

    useEffect(() => {
        setSystemsSelection({})
    }, [userSelected])

    useEffect(() => {
        return () => {
            setSelection({})
            setSystemsSelection({})
        }
    }, [])

    const onReplicate = () => {
        if (!userSelected) return toast.warning('Selecione um usuário')

        if (Object.values(systemsSelection).filter(Boolean).length === 0)
            return toast.warning(
                'Selecione no mínimo um sistema para replicar os acessos'
            )

        mutate({
            systemList: Object.keys(systemsSelection)
                .map(Number)
                .filter(Boolean),
            userFrom: userSelected.userId,
            userTo: user!.userId,
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-[680px] h-full max-h-[720px] flex flex-col overflow-hidden">
                <DialogHeader>
                    <DialogTitle>Replicar Acessos</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col flex-1 space-y-4 overflow-hidden">
                    <div className="space-y-2">
                        <Label>Usuário</Label>
                        <MultiSelect
                            isOpen={isMultiSelectOpen}
                            onClose={onMultiSelectClose}
                            onOpen={onMultiSelectOpen}
                            options={data.map((user) => ({
                                label: `${
                                    user.registration
                                        ? `${user.registration} - `
                                        : ''
                                } ${user.name}`,
                                value: getId(user),
                            }))}
                            onChange={(value) => setSelection(value)}
                            selectedValues={selection}
                            enableMultiSelect={false}
                            label={
                                isUsersPending
                                    ? 'Carregando usuários...'
                                    : isUsersError
                                    ? 'Erro ao listar usuários'
                                    : 'Selecione um usuário para replicar o acesso'
                            }
                        />
                    </div>
                    <UserSystems
                        userId={userSelected?.userId || null}
                        systemsSelection={systemsSelection}
                        disabled={isUsersPending || isReplicatePending}
                        setSystemsSelection={setSystemsSelection}
                        onReplicate={onReplicate}
                        onClose={onClose}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ReplicateDialog

import { Check, ChevronsUpDown } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from '@/components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { useCallback } from 'react'

type SelectionState = Record<string, boolean>

interface MultiSelectProps {
    isOpen: boolean
    label?: string
    emptyLabel?: string
    placeholder?: string
    enableMultiSelect?: boolean
    options: {
        value: string
        label: string
    }[]
    selectedValues: SelectionState
    onChange: (value: SelectionState) => void
    onOpen: () => void
    onClose: () => void
    customFilter?: (value: string, search: string) => number
}

export function MultiSelect({
    label,
    emptyLabel,
    placeholder,
    options,
    isOpen,
    selectedValues,
    enableMultiSelect = true,
    customFilter,
    onOpen,
    onClose,
    onChange,
}: MultiSelectProps) {
    const getIsSomeSelected = () => {
        return Object.values(selectedValues).some(Boolean)
    }

    const getIsAllSelected = useCallback(() => {
        return (
            Object.values(selectedValues).filter(Boolean).length ===
            options.length
        )
    }, [selectedValues, options])

    const getIsSelected = useCallback(
        (value: string) => {
            return !!selectedValues[value]
        },
        [selectedValues]
    )

    const onChangeSelectedState = useCallback(
        (value: string) => {
            if (enableMultiSelect) {
                onChange({
                    ...selectedValues,
                    [value]: !selectedValues[value],
                })
            } else {
                onChange({
                    [value]: true,
                })
            }
        },
        [selectedValues]
    )

    const onSelectAll = useCallback(() => {
        if (enableMultiSelect)
            onChange(
                options.reduce(
                    (acc, option) => ({
                        ...acc,
                        [option.value]: !getIsAllSelected(),
                    }),
                    {}
                )
            )
    }, [options])

    const getSelectedValues = useCallback(() => {
        return Object.values(selectedValues).filter(Boolean)
    }, [selectedValues])

    const getButtonText = () => {
        const defaultLabel = label || 'Selecionar valores'

        if (!enableMultiSelect) {
            const selectedOption = options.find(
                (option) => selectedValues[option.value]
            )

            if (selectedOption) return selectedOption?.label

            return defaultLabel
        }

        if (getIsSomeSelected()) {
            const isPlural = getSelectedValues().length > 1

            return `${getSelectedValues().length} ite${
                isPlural ? 'ns' : 'm'
            } selecionado${isPlural ? 's' : ''}`
        }

        return defaultLabel
    }

    return (
        <Popover
            open={isOpen}
            onOpenChange={(open) => (open ? onOpen() : onClose())}
        >
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={isOpen}
                    className="justify-between w-full font-normal"
                >
                    {getButtonText()}
                    <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[400px] p-0 overflow-hidden">
                <Command filter={customFilter}>
                    <CommandInput
                        placeholder={placeholder || 'Buscar item...'}
                    />
                    <CommandEmpty>
                        {emptyLabel || 'Nenhum item selecionado'}
                    </CommandEmpty>
                    <CommandGroup className="max-h-[200px] overflow-y-auto">
                        {enableMultiSelect && (
                            <CommandItem onSelect={onSelectAll}>
                                <Check
                                    className={cn(
                                        'mr-2 h-4 w-4',
                                        getIsAllSelected()
                                            ? 'opacity-100'
                                            : 'opacity-0'
                                    )}
                                />
                                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                    Selecionar todos
                                </span>
                            </CommandItem>
                        )}
                        <>
                            {options.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    value={option.value}
                                    onSelect={onChangeSelectedState}
                                >
                                    <Check
                                        className={cn(
                                            'mr-2 h-4 w-4',
                                            getIsSelected(option.value)
                                                ? 'opacity-100'
                                                : 'opacity-0'
                                        )}
                                    />
                                    <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                                        {option.label}
                                    </span>
                                </CommandItem>
                            ))}
                        </>
                    </CommandGroup>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

import Header from '@/components/Header'
import ChangePasswordDialog from '@/components/ChangePasswordDialog'
import { Outlet } from 'react-router-dom'
import { ReactNode } from 'react'
import Sidenav from '@/components/Sidenav'

interface MainLayoutProps {
    children?: ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
    return (
        <>
            <div className="w-full h-screen overflow-hidden bg-background">
                <Header />
                <div className="flex w-full overflow-hidden h-container-header">
                    <Sidenav />
                    <div className="flex-1 overflow-hidden">
                        {children || <Outlet />}
                    </div>
                </div>
            </div>
            <ChangePasswordDialog />
        </>
    )
}

export default MainLayout

import { cn } from '@/lib/utils'
import { cva, VariantProps } from 'class-variance-authority'
import { useTheme } from 'next-themes'
import { Toaster as Sonner, toast } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
    const { theme = 'system' } = useTheme()

    return (
        <Sonner
            theme={theme as ToasterProps['theme']}
            richColors
            closeButton
            className="toaster group"
            toastOptions={{
                classNames: {
                    toast: 'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
                    description: 'group-[.toast]:text-muted-foreground',
                    actionButton:
                        'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
                    cancelButton:
                        'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
                    error: 'group-[.toaster]:bg-red-300',
                    warning: 'group-[.toaster]:bg-yellow-300',
                    success: 'group-[.toaster]:bg-green-300',
                    info: 'group-[.toaster]:bg-blue-300',
                },
            }}
            {...props}
        />
    )
}

const toastStyleVariants = cva('', {
    variants: {
        variant: {
            default: 'toast',
            success: 'toast-success',
            warning: 'toast-warning',
            info: 'toast-info',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
})

type UseToastProps = {
    message: Parameters<typeof toast>[0]
    config?: Parameters<typeof toast>[1] &
        VariantProps<typeof toastStyleVariants>
}

const useToast = () => {
    const trigger = ({ message, config }: UseToastProps) =>
        toast(message, {
            ...config,
            className: cn(
                toastStyleVariants({ variant: config?.variant }),
                config?.className
            ),
        })

    return { toast: trigger }
}

export { Toaster, useToast }

import instance from '..'
import { System } from './types'

const BASE_ROUTE = '/system'

export const fetchSystems = async () => {
    const { data } = await instance.get<System[]>(BASE_ROUTE)

    return data
}

import { fetchUserSystems } from '@/api/business/users'
import { FetchUserSystemsProps } from '@/api/business/users/types'
import { useQuery } from '@tanstack/react-query'

export const userSystemsKeys = {
    all: ['userSystems'] as const,
    lists: () => [...userSystemsKeys.all, 'list'] as const,
    list: (initial: number, end: number) =>
        [...userSystemsKeys.lists(), { initial, end }] as const,
    details: () => [...userSystemsKeys.all, 'detail'] as const,
    detail: (params: number) => [...userSystemsKeys.details(), params] as const,
}

type UseUserSystemsQueryProps = FetchUserSystemsProps & {
    enabled: boolean
}

export const useUserSystemsQuery = ({
    userId,
    enabled,
}: UseUserSystemsQueryProps) => {
    return useQuery({
        queryFn: () => fetchUserSystems({ userId }),
        queryKey: userSystemsKeys.detail(userId),
        enabled,
    })
}

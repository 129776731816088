import {
    TypographyH1,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import { useUserQuery } from '@/queries/useUsersQuery'
import { Link, Outlet, useParams } from 'react-router-dom'
import NotFound from '../NotFound'
import { Ban } from 'lucide-react'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { ROUTES } from '@/consts/routes'
import UserLink from './components/UserLink'
import UserDetailSkeleton from './components/UserDetailSkeleton'

const UserDetail = () => {
    const { user: userParam } = useParams()

    const { data, isPending, isFetching, isError } = useUserQuery()

    const userFound = data?.find((user) => user.user === userParam)

    if (isError)
        return (
            <div className="p-4 text-center">
                <TypographyP className="text-red-500">
                    Houve um erro ao listar usuário
                </TypographyP>
            </div>
        )

    if (isPending || isFetching) return <UserDetailSkeleton />

    if (!userFound) return <NotFound />

    const { blocked, blockedReason, name } = userFound!

    return (
        <div className="container flex flex-col h-full p-4 space-y-6 overflow-hidden">
            <div>
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link to={ROUTES.USERS.index}>Usuários</Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>{name}</BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>
            <div>
                <TypographyH1>Detalhamento de usuário</TypographyH1>
                {blocked && (
                    <div className="flex items-center gap-2">
                        <Ban size={14} className="text-destructive" />
                        <TypographyP>Usuário bloqueado - </TypographyP>
                        <TypographyMuted>{blockedReason}</TypographyMuted>
                    </div>
                )}
            </div>
            <div className="flex-1 grid grid-cols-[260px_1fr] gap-4 overflow-hidden">
                <div className="space-y-2">
                    <div>
                        <div className="p-3">
                            <TypographyP className="font-semibold">
                                Geral
                            </TypographyP>
                        </div>
                        <UserLink to={ROUTES.USER_DATA.index}>
                            Dados do usuário
                        </UserLink>
                        <UserLink to={ROUTES.USER_SYSTEMS.index}>
                            Sistemas
                        </UserLink>
                        <UserLink to={ROUTES.USER_PASSWORD.index}>
                            Senha
                        </UserLink>
                    </div>
                </div>
                <Outlet />
            </div>
        </div>
    )
}

export default UserDetail

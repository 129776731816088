import bbudget from '@/assets/appIcons/bbudget.png'
import bcashflow from '@/assets/appIcons/bcashflow.png'
import bhuman from '@/assets/appIcons/bhuman.png'
import binventory from '@/assets/appIcons/binventory.png'
import blab from '@/assets/appIcons/blab.png'
import monitordequalidade from '@/assets/appIcons/blab.png'
import breport from '@/assets/appIcons/breport.png'
import bsupply from '@/assets/appIcons/bsupply.png'
import centralderesiduo from '@/assets/appIcons/centralderesiduo.png'
import defaultImage from '@/assets/appIcons/default.png'
import gerenvase from '@/assets/appIcons/gerenvase.png'
import painelmonitoramento from '@/assets/appIcons/monitordecarregamento.png'
import newbdoc from '@/assets/appIcons/newbdoc.png'
import bdoc from '@/assets/appIcons/bdoc.png'
import pedbeer from '@/assets/appIcons/pedbeer.png'
import pedgin from '@/assets/appIcons/pedgin.png'
import portalcusto from '@/assets/appIcons/portalcustos.png'
import prodbeer from '@/assets/appIcons/prodbeer.png'
import intranet from '@/assets/appIcons/intranet.png'
import falela from '@/assets/appIcons/falela.png'
import planningbeer from '@/assets/appIcons/planningbeer.png'
import portalfornecedor from '@/assets/appIcons/portalfornecedor.png'
import bload from '@/assets/appIcons/bload.png'
import gman from '@/assets/appIcons/gman.png'
import smart from '@/assets/appIcons/smart.png'
import bbus from '@/assets/appIcons/bbuss.png'
import baccess from '@/assets/appIcons/baccess.png'
import monitorsa from '@/assets/appIcons/monitorsa.png'
import crweb from '@/assets/appIcons/crweb.png'
import logestoque from '@/assets/appIcons/logestoque.png'

const images = {
    bbudget,
    bcashflow,
    bhuman,
    binventory,
    blab,
    breport,
    bsupply,
    centralderesiduo,
    default: defaultImage,
    gerenvase,
    painelmonitoramento,
    newbdoc,
    bdoc,
    pedbeer,
    pedgin,
    portalcusto,
    prodbeer,
    intranet,
    falela,
    planningbeer,
    portalfornecedor,
    monitordequalidade,
    bload,
    gman,
    smart,
    bbus,
    baccess,
    monitorsa,
    crweb,
    logestoque,
}

export default function getAppIcon(asset: string) {
    return images[asset.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase()]
}

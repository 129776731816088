import { System } from '@/api/business/systems/types'
import Image from '@/components/Image'
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import getAppIcon from '@/utils/getAppIcon'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { motion } from 'framer-motion'
import { ShieldAlert } from 'lucide-react'

type SystemCardProps = System

const SystemCard = ({
    description,
    name,
    systemId,
    webUrl,
    supportN1,
}: SystemCardProps) => {
    const cardVariants = {
        hidden: { opacity: 0, y: 10 },
        visible: { opacity: 1, y: 0 },
    }

    const image = getAppIcon(name)
    const fallback = getAppIcon('default')

    const onCardClick = () => {
        if (webUrl) open(webUrl, '_blank')
    }

    return (
        <motion.div
            layoutId={systemId.toString()}
            className="relative flex flex-col items-center justify-center gap-2 p-4 rounded-md shadow cursor-pointer hover:bg-accent bg-card"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            onClick={onCardClick}
            transition={{
                delay: 0.015,
                duration: 0.2,
                ease: 'easeOut',
            }}
        >
            <Image
                src={image || fallback}
                className="h-[56px]"
                fallbackSrc={fallback}
            />

            <TypographyP className="text-center">{name}</TypographyP>
            {!supportN1 && (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger className="absolute top-3 right-3">
                            <ShieldAlert className="text-amber-600" size={18} />
                        </TooltipTrigger>
                        <TooltipContent>
                            <TypographyP className="text-xs">
                                Não replicável
                            </TypographyP>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            )}
            <TypographyMuted className="text-sm text-center first-letter:capitalize">
                {description.toLowerCase()}
            </TypographyMuted>
        </motion.div>
    )
}

export default SystemCard

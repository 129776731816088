import { Navigate, Outlet, useLocation } from 'react-router-dom'
import LoadingScreen from '@/pages/LoadingScreen'
import { ReactNode, useEffect } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { validateUserToken } from '@/api/auth/user'
import { getItem, setItem } from '@/utils/storage'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import NotAllowed from '@/pages/NotAllowed'

interface RequireAuthProps {
    isAllowed: boolean
    redirectPath?: string
    children?: ReactNode
}

const stateSelector = (state: Store) => ({
    user: state.authSlice.state.user,
    login: state.authSlice.actions.login,
    logout: state.authSlice.actions.logout,
})

const RequireAuth = ({
    isAllowed,
    redirectPath,
    children,
}: RequireAuthProps) => {
    const { user, login, logout } = useBaseStore(stateSelector)

    const token = getItem(localStorage, 'token')
    const location = useLocation()

    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: validateUserToken,
        onSuccess: (data) => {
            console.log(data)
            setItem(localStorage, 'token', token)
            if (data.token) {
                login({
                    token,
                    user: data.token.usuario.nome,
                    userId: data.token.usuario.cod_usuario,
                })
            }
        },
        onError: () => {
            logout(queryClient)
        },
    })

    useEffect(() => {
        if (token && !user) {
            mutate()
        }
    }, [token, mutate])

    if (isPending) return <LoadingScreen />

    if (!token) {
        return (
            <Navigate
                to="/login"
                replace
                state={{
                    path:
                        redirectPath ||
                        `${location.pathname}${location.search}`,
                }}
            />
        )
    }

    if (!isAllowed) {
        return <NotAllowed />
    }

    return children || <Outlet />
}

export default RequireAuth

import { motion, Variants } from 'framer-motion'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import { cn } from '@/lib/utils'
import { ROUTES } from '@/consts/routes'
import { TypographyP } from '../ui/typography'
import { NavLink } from 'react-router-dom'

const stateSelector = (state: Store) => ({
    isOpen: state.appConfigSlice.state.isSidenavOpen,
    setIsOpen: state.appConfigSlice.actions.setSidenavOpen,
})

const Sidenav = () => {
    const { isOpen } = useBaseStore(stateSelector)

    const routes = Object.values(ROUTES).filter(
        (route) => route.shouldShowOnSidenav
    )

    const parentVariant: Variants = {
        initial: {
            x: isOpen ? 0 : -300,
            width: isOpen ? 300 : 0,
        },
        animate: {
            x: isOpen ? 0 : -300,
            width: isOpen ? 300 : 0,
        },
    }

    return (
        <motion.div
            className={cn('p-4 w-[300px] overflow-hidden')}
            variants={parentVariant}
            transition={{
                bounce: 2,
            }}
            initial="initial"
            animate="animate"
        >
            <div
                className={cn(
                    'w-full p-4 h-full bg-card shadow border rounded-md'
                )}
            >
                {routes.map((route) => {
                    const { index, icon: Icon, label } = route

                    return (
                        <NavLink
                            key={index}
                            className={({ isActive }) =>
                                cn(
                                    'flex items-center group gap-2 p-2 rounded-md hover:bg-accent',
                                    isActive && 'active'
                                )
                            }
                            to={index}
                        >
                            {Icon && (
                                <Icon
                                    size={16}
                                    className="group-[.active]:text-primary dark:group-[.active]:text-primary-300"
                                />
                            )}
                            <TypographyP className="group-[.active]:text-primary dark:group-[.active]:text-primary-300">
                                {label}
                            </TypographyP>
                        </NavLink>
                    )
                })}
            </div>
        </motion.div>
    )
}
export default Sidenav

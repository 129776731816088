import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Skeleton } from '@/components/ui/skeleton'
import { ROUTES } from '@/consts/routes'
import { Link } from 'react-router-dom'

const UserDetailSkeleton = () => {
    return (
        <div className="container flex flex-col h-full p-4 space-y-6 overflow-hidden">
            <div>
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link to={ROUTES.USERS.index}>Usuários</Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>
                                <Skeleton className="w-[200px] h-4" />
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>
            <div>
                <Skeleton className="w-[300px] h-8" />
            </div>
            <div className="space-y-4">
                <Skeleton className="w-[600px] h-6" />
                <Skeleton className="w-[600px] h-6" />
                <Skeleton className="w-[600px] h-6" />
            </div>
        </div>
    )
}

export default UserDetailSkeleton

import { cn } from '@/lib/utils'
import { forwardRef, ImgHTMLAttributes, useState } from 'react'

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
    fallbackSrc?: string
}

const Image = forwardRef<HTMLImageElement, ImageProps>(
    ({ className, src, fallbackSrc, ...props }, ref) => {
        const [image, setImage] = useState(src)

        return (
            <img
                ref={ref}
                className={cn(className)}
                src={image}
                onError={() => {
                    if (fallbackSrc) setImage(fallbackSrc)
                }}
                {...props}
            />
        )
    }
)

export default Image

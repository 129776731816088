import { Home, Lock, LogIn, LucideIcon, ShieldBan, User2 } from 'lucide-react'

export type RouteLabel =
    | 'Login'
    | 'Recuperar senha'
    | 'Not Found'
    | 'Home'
    | 'Usuários'
    | 'Dados do usuário'
    | 'Sistemas'
    | 'Senha'
    | 'Criar usuário'

export type Route = {
    index: string
    label: RouteLabel
    icon: LucideIcon | null
    shouldShowOnSidenav: boolean
    getParentRoute: () => string
    getRoutePath: () => string
}

export const ROUTES: Record<string, Route> = {
    HOME: {
        index: '/',
        label: 'Home',
        icon: Home,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    USERS: {
        index: '/usuarios',
        label: 'Usuários',
        icon: User2,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    CREATE_USER: {
        index: 'criar-usuario',
        label: 'Criar usuário',
        icon: User2,
        shouldShowOnSidenav: false,
        getParentRoute: () =>
            `${ROUTES.USERS.index}/${ROUTES.CREATE_USER.index}`,
        getRoutePath: () => `${ROUTES.USERS.index}/${ROUTES.CREATE_USER.index}`,
    },
    USER_DATA: {
        index: 'dados',
        label: 'Dados do usuário',
        icon: User2,
        shouldShowOnSidenav: false,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    USER_SYSTEMS: {
        index: 'sistemas',
        label: 'Sistemas',
        icon: User2,
        shouldShowOnSidenav: false,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    USER_PASSWORD: {
        index: 'senha',
        label: 'Senha',
        icon: User2,
        shouldShowOnSidenav: false,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    LOGIN: {
        index: 'login',
        label: 'Login',
        icon: LogIn,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.LOGIN.index,
    },
    RECOVER_PASSWORD: {
        index: 'recuperar-senha',
        label: 'Recuperar senha',
        icon: Lock,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.RECOVER_PASSWORD.index,
    },
    NOT_FOUND: {
        index: '*',
        label: 'Not Found',
        icon: ShieldBan,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.NOT_FOUND.index,
    },
} as const

import { fetchSystems } from '@/api/business/systems'
import { useQuery } from '@tanstack/react-query'

export const systemKeys = {
    all: ['system'] as const,
    lists: () => [...systemKeys.all, 'list'] as const,
    list: (initial: number, end: number) =>
        [...systemKeys.lists(), { initial, end }] as const,
    details: () => [...systemKeys.all, 'detail'] as const,
    detail: (params: string) => [...systemKeys.details(), params] as const,
}

export const useSystemQuery = () => {
    return useQuery({
        queryFn: () => fetchSystems(),
        queryKey: systemKeys.lists(),
    })
}

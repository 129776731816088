import { createUser } from '@/api/business/users'
import { Button } from '@/components/ui/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
    TypographyH1,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import PasswordDialog from './components/PasswordDialog'
import useDisclosure from '@/hooks/useDisclosure'
import { isAxiosError } from '@/api/business'
import { toast } from 'sonner'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { ROUTES } from '@/consts/routes'
import { Link } from 'react-router-dom'
import { userKeys } from '@/queries/useUsersQuery'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { X } from 'lucide-react'

const enterprises = [
    {
        id: 302030,
        label: 'FRUTAL',
    },
    {
        id: 11004,
        label: 'PETRÓPOLIS',
    },
    {
        id: 901010,
        label: 'VELVO',
    },
]

const createUserSchema = z.object({
    username: z.string().min(1, 'Campo obrigatório'),
    email: z.union([z.literal(''), z.string().email()]),
    name: z.string().min(1, 'Campo obrigatório'),
    registration: z.string().optional(),
    enterprise: z.coerce
        .number({ message: 'Matrícula deve ser no formato número' })
        .optional(),
    cpf: z.string().optional(),
})

type CreateUserSchema = z.infer<typeof createUserSchema>

const CreateUser = () => {
    const [user, setUser] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const queryClient = useQueryClient()

    const {
        isOpen: isPasswordDialogOpen,
        onClose: onPasswordDialogClose,
        onOpen: onPasswordDialogOpen,
    } = useDisclosure()

    const form = useForm<CreateUserSchema>({
        resolver: zodResolver(createUserSchema),
        defaultValues: {
            username: '',
            email: '',
            name: '',
            registration: '',
            cpf: '',
            enterprise: undefined,
        },
    })

    const { mutate, isPending } = useMutation({
        mutationFn: createUser,
        onSuccess: (data, vars) => {
            setNewPassword(data.password)
            onPasswordDialogOpen()
            setUser(vars.username)
            queryClient.invalidateQueries({ queryKey: userKeys.all })
            form.reset()
        },
        onError: (err) => {
            let message =
                'Houve um erro ao criar usuário. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                message = err.response?.data.error || message
            }

            toast(message)
        },
    })

    const onSubmit = (values: CreateUserSchema) => {
        mutate({
            ...values,
        })
    }

    const username = form.watch('username')

    return (
        <>
            <div className="h-full overflow-auto">
                <div className="container p-4 space-y-4 max-w-[640px]">
                    <div>
                        <Breadcrumb>
                            <BreadcrumbList>
                                <BreadcrumbItem>
                                    <BreadcrumbLink asChild>
                                        <Link to={ROUTES.USERS.index}>
                                            Usuários
                                        </Link>
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                                <BreadcrumbSeparator />
                                <BreadcrumbItem>
                                    <BreadcrumbPage>
                                        Criar usuário
                                    </BreadcrumbPage>
                                </BreadcrumbItem>
                            </BreadcrumbList>
                        </Breadcrumb>
                    </div>
                    <div>
                        <TypographyH1>Novo usuário</TypographyH1>
                        <TypographyMuted>
                            Preencha os campos abaixo com os dados do novo
                            usuário a ser criado
                        </TypographyMuted>
                    </div>
                    <Form {...form}>
                        <form
                            className="space-y-4"
                            onSubmit={form.handleSubmit(onSubmit)}
                        >
                            <FormField
                                name="name"
                                control={form.control}
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>
                                                Nome{' '}
                                                <span className="text-destructive">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                            <FormField
                                name="username"
                                control={form.control}
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>
                                                Usuário{' '}
                                                <span className="text-destructive">
                                                    *
                                                </span>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                            <FormField
                                name="email"
                                control={form.control}
                                render={({ field }) => {
                                    const emailSuggested = `${username}@cervejariacidadeimperial.com.br`

                                    return (
                                        <FormItem>
                                            <FormLabel>Email</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            {username && (
                                                <div
                                                    className="inline-block px-1.5 py-0.5  rounded-full bg-primary-100/30 cursor-pointer"
                                                    onClick={() =>
                                                        form.setValue(
                                                            'email',
                                                            emailSuggested
                                                        )
                                                    }
                                                >
                                                    <TypographyP className="text-xs text-primary">
                                                        {emailSuggested}
                                                    </TypographyP>
                                                </div>
                                            )}
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                            <FormField
                                name="enterprise"
                                control={form.control}
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>Empresa</FormLabel>
                                            <FormControl>
                                                <div className="flex gap-2">
                                                    <Select
                                                        onValueChange={
                                                            field.onChange
                                                        }
                                                        value={field.value?.toString()}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Selecione uma empresa" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                {enterprises.map(
                                                                    (
                                                                        enterprise
                                                                    ) => {
                                                                        return (
                                                                            <SelectItem
                                                                                key={
                                                                                    enterprise.id
                                                                                }
                                                                                value={enterprise.id.toString()}
                                                                            >
                                                                                {
                                                                                    enterprise.label
                                                                                }
                                                                            </SelectItem>
                                                                        )
                                                                    }
                                                                )}
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                    <Button
                                                        variant="ghost"
                                                        size="icon"
                                                        type="button"
                                                        onClick={() =>
                                                            field.onChange('')
                                                        }
                                                    >
                                                        <X size={14} />
                                                    </Button>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                            <FormField
                                name="registration"
                                control={form.control}
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>Matrícula</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                            <FormField
                                name="cpf"
                                control={form.control}
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>CPF</FormLabel>
                                            <FormControl>
                                                <Input {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )
                                }}
                            />
                            <div className="flex justify-end">
                                <Button
                                    className="ml-auto"
                                    disabled={isPending}
                                >
                                    Confirmar
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
            <PasswordDialog
                isOpen={isPasswordDialogOpen}
                onClose={onPasswordDialogClose}
                password={newPassword}
                user={user}
            />
        </>
    )
}

export default CreateUser

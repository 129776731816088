import instance from '..'
import { System } from '../systems/types'
import {
    CreateUserProps,
    CreateUserResponse,
    FetchUserSystemsProps,
    ReplicateUserAccessProps,
    ResetUserPasswordProps,
    ResetUserPasswordResponse,
    User,
} from './types'

const BASE_ROUTE = '/user'

export const fetchUsers = async () => {
    const { data } = await instance.get<User[]>(BASE_ROUTE)

    return data
}

export const fetchUserSystems = async ({ userId }: FetchUserSystemsProps) => {
    const { data } = await instance.post<System[]>(BASE_ROUTE + '/system', {
        id: userId,
    })

    return data
}

export const createUser = async (user: CreateUserProps) => {
    const { data } = await instance.put<CreateUserResponse>(BASE_ROUTE, user)

    return data
}

export const resetUserPassword = async ({ userId }: ResetUserPasswordProps) => {
    const { data } = await instance.post<ResetUserPasswordResponse>(
        BASE_ROUTE + '/reset-password',
        {
            id: userId,
        }
    )

    return data
}

export const replicateUserAccess = async ({
    systemList,
    userFrom,
    userTo,
}: ReplicateUserAccessProps) => {
    const { data } = await instance.post(BASE_ROUTE + '/replicate', {
        systemList,
        userFrom,
        userTo,
    })

    return data
}

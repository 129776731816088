import { Button } from '@/components/ui/button'
import { TypographyH3, TypographyMuted } from '@/components/ui/typography'
import PasswordDialog from './components/PasswordDialog'
import useDisclosure from '@/hooks/useDisclosure'
import { useEffect, useState } from 'react'
import ConfirmDialog from './components/ConfirmDialog'

const UserPassword = () => {
    const {
        isOpen: isPasswordDialogOpen,
        onClose: onPasswordDialogClose,
        onOpen: onPasswordDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isConfirmDialogOpen,
        onClose: onConfirmDialogClose,
        onOpen: onConfirmDialogOpen,
    } = useDisclosure()

    const [newPasword, setNewPassword] = useState(' ')

    useEffect(() => {
        return () => {
            setNewPassword('')
        }
    }, [])

    return (
        <>
            <div className="p-10 space-y-4 rounded-md bg-muted h-[400px]">
                <div>
                    <TypographyH3>Resetar senha</TypographyH3>
                    <TypographyMuted>
                        Ao resetar a senha será gerada uma senha randômica para
                        o usuário
                    </TypographyMuted>
                </div>
                <div>
                    <Button onClick={onConfirmDialogOpen}>Resetar</Button>
                </div>
            </div>
            <PasswordDialog
                isOpen={isPasswordDialogOpen}
                onClose={onPasswordDialogClose}
                password={newPasword}
            />
            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                onClose={onConfirmDialogClose}
                onPasswordDialogOpen={onPasswordDialogOpen}
                setNewPassword={setNewPassword}
            />
        </>
    )
}

export default UserPassword

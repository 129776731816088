import { User } from '@/api/business/users/types'
import { TableData } from '@/components/Table/type'
import { useUserQuery } from '@/queries/useUsersQuery'
import { useMemo } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import Table from '@/components/Table'
import { Button } from '@/components/ui/button'
import { Eye } from 'lucide-react'
import { TypographyH3 } from '@/components/ui/typography'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/consts/routes'
import { isAxiosError } from 'axios'

type UsersTable = TableData<User>

const columnHelper = createColumnHelper<UsersTable>()

const Users = () => {
    const { data = [], isPending, isError, isFetching, error } = useUserQuery()

    const tableData = useMemo(() => data, [data])

    const navigate = useNavigate()

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => {
                    const { user } = row.original
                    return (
                        <div className="flex justify-center">
                            <Button
                                className="text-primary hover:text-primary"
                                variant="ghost"
                                size="icon"
                                onClick={() =>
                                    navigate(
                                        `${ROUTES['USERS'].index}/${user}/${ROUTES['USER_DATA'].index}`
                                    )
                                }
                            >
                                <Eye size={14} />
                            </Button>
                        </div>
                    )
                },
                meta: {
                    cell: {
                        className: 'p-0',
                    },
                },
                size: 80,
            }),
            columnHelper.accessor('registration', {
                header: 'Matrícula',
                size: 100,
            }),
            columnHelper.accessor('name', {
                header: 'Nome',
                size: 400,
            }),
            columnHelper.accessor('email', {
                header: 'Email',
                size: 400,
            }),
            columnHelper.accessor('user', {
                header: 'Usuário',
                size: 200,
            }),
            columnHelper.accessor('enterprise', {
                header: 'Filial',
                size: 100,
            }),
            columnHelper.accessor(({ blocked }) => (blocked ? 'Sim' : 'Não'), {
                header: 'Bloqueado',
            }),
            columnHelper.accessor('blockedReason', {
                header: 'Motivo bloqueio',
                size: 400,
            }),
        ],
        []
    )

    if (!data) return 'Loading'

    return (
        <div className="flex flex-col h-full gap-4 p-4 overflow-hidden">
            <div className="flex-1 overflow-hidden">
                <Table
                    data={tableData}
                    columns={columns}
                    tableHeader={
                        <div>
                            <TypographyH3>Usuários</TypographyH3>
                        </div>
                    }
                    isLoading={isPending}
                    isError={isError}
                    isFetching={isFetching}
                    tableActions={
                        <Button onClick={() => navigate('criar-usuario')}>
                            Criar usuário
                        </Button>
                    }
                    errorMessage={
                        isAxiosError(error)
                            ? error.response?.data.error
                            : 'Houve um erro ao carregar os usuários'
                    }
                    persist={{
                        canPersist: true,
                        key: 'users-table',
                    }}
                />
            </div>
        </div>
    )
}

export default Users

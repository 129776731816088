import { cn } from '@/lib/utils'
import { NavLink, NavLinkProps } from 'react-router-dom'

export type UserLinkProps = NavLinkProps

const UserLink = ({ className, children, ...props }: UserLinkProps) => {
    return (
        <NavLink
            {...props}
            className={({ isActive }) =>
                cn(
                    className,
                    'block p-3 rounded-md hover:bg-accent text-muted-foreground',
                    isActive && 'text-primary dark:text-primary-300'
                )
            }
        >
            {children}
        </NavLink>
    )
}

export default UserLink

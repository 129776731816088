import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { ROUTES } from '@/consts/routes'
import { Clipboard, ClipboardCheck, Eye } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type PasswordDialogProps = {
    isOpen: boolean
    user: string
    password: string
    onClose: () => void
}

const PasswordDialog = ({
    isOpen,
    user,
    password,
    onClose,
}: PasswordDialogProps) => {
    const navigate = useNavigate()

    const [isPasswordCopied, setIsPasswordCopied] = useState(false)
    const [isUserCopied, setIsUserCopied] = useState(false)

    const onUserCopy = () => {
        navigator.clipboard.writeText(user)
        setIsUserCopied(true)

        setTimeout(() => {
            setIsUserCopied(false)
        }, 5000)
    }

    const onPasswordCopy = () => {
        navigator.clipboard.writeText(password)
        setIsPasswordCopied(true)

        setTimeout(() => {
            setIsPasswordCopied(false)
        }, 5000)
    }

    const onNavigate = () => {
        navigate(
            `${ROUTES['USERS'].index}/${user}/${ROUTES['USER_DATA'].index}`
        )
    }

    useEffect(() => {
        return () => {
            setIsPasswordCopied(false)
            setIsUserCopied(false)
        }
    }, [])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="p-6 bg-white rounded-lg shadow-md dark:bg-gray-900">
                <DialogHeader className="mb-4">
                    <DialogTitle className="text-lg font-bold text-gray-900 dark:text-white">
                        Usuário criado com sucesso
                    </DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                    <div className="flex items-center gap-4 p-3 border rounded-lg bg-card">
                        <TypographyP>
                            <span className="text-muted-foreground">
                                Usuário:
                            </span>{' '}
                            <span className="font-semibold text-card-foreground">
                                {user}
                            </span>
                        </TypographyP>
                        <Button
                            size="icon"
                            variant="ghost"
                            onClick={onUserCopy}
                            className="ml-auto"
                        >
                            {isUserCopied ? (
                                <ClipboardCheck
                                    className="text-emerald-500"
                                    size={16}
                                />
                            ) : (
                                <Clipboard size={16} />
                            )}
                        </Button>
                    </div>
                    <div className="flex items-center gap-4 p-3 border rounded-lg bg-card">
                        <TypographyP>
                            <span className="text-muted-foreground">
                                Senha:
                            </span>{' '}
                            <span className="font-semibold text-card-foreground">
                                {password}
                            </span>
                        </TypographyP>
                        <Button
                            size="icon"
                            variant="ghost"
                            onClick={onPasswordCopy}
                            className="ml-auto"
                        >
                            {isPasswordCopied ? (
                                <ClipboardCheck
                                    className="text-emerald-500"
                                    size={16}
                                />
                            ) : (
                                <Clipboard size={16} />
                            )}
                        </Button>
                    </div>
                </div>
                <DialogFooter className="mt-6">
                    <Button variant="ghost" onClick={onClose}>
                        Fechar
                    </Button>
                    <Button onClick={onNavigate} className="gap-2">
                        <Eye size={14} />
                        Visualizar usuário
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default PasswordDialog

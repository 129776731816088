import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { useUserQuery } from '@/queries/useUsersQuery'
import { useParams } from 'react-router-dom'
import NotFound from '../NotFound'

const UserData = () => {
    const { user: userParam } = useParams()

    const { data } = useUserQuery()

    const userFound = data?.find((user) => user.user === userParam)

    if (!userFound) return <NotFound />

    const { email, enterprise, name, registration, user } = userFound

    return (
        <div className="p-10 space-y-4 overflow-auto rounded-md bg-muted h-fit">
            <div className="space-y-4">
                <div>
                    <TypographyMuted>Nome</TypographyMuted>
                    <TypographyP>{name || '-'}</TypographyP>
                </div>
                <div>
                    <TypographyMuted>Usuário</TypographyMuted>
                    <TypographyP>{user || '-'}</TypographyP>
                </div>
                <div>
                    <TypographyMuted>Email</TypographyMuted>
                    <TypographyP>{email || '-'}</TypographyP>
                </div>
                <div>
                    <TypographyMuted>Empresa</TypographyMuted>
                    <TypographyP>{enterprise || '-'}</TypographyP>
                </div>
                <div>
                    <TypographyMuted>Matrícula</TypographyMuted>
                    <TypographyP>{registration || '-'}</TypographyP>
                </div>
            </div>
        </div>
    )
}

export default UserData

import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { TypographyP } from '@/components/ui/typography'
import { Clipboard, ClipboardCheck } from 'lucide-react'
import { useEffect, useState } from 'react'

type PasswordDialogProps = {
    isOpen: boolean
    password: string
    onClose: () => void
}

const PasswordDialog = ({ isOpen, password, onClose }: PasswordDialogProps) => {
    const [isCopied, setIsCopied] = useState(false)

    const onCopy = () => {
        navigator.clipboard.writeText(password)
        setIsCopied(true)

        setTimeout(() => {
            setIsCopied(false)
        }, 5000)
    }

    useEffect(() => {
        return () => {
            setIsCopied(false)
        }
    }, [])

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Nova senha gerada com sucesso</DialogTitle>
                </DialogHeader>
                <div className="flex items-center gap-2">
                    <TypographyP>
                        Senha: <span className="font-semibold">{password}</span>
                    </TypographyP>
                    <Button size="icon" variant="ghost" onClick={onCopy}>
                        {isCopied ? (
                            <ClipboardCheck
                                className="text-emerald-500"
                                size={16}
                            />
                        ) : (
                            <Clipboard size={16} />
                        )}
                    </Button>
                </div>
                <DialogFooter>
                    <Button onClick={onClose}>Fechar</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default PasswordDialog

import { getInitials } from '@/utils/stringFormatter'
import { Menu, ChevronsRight, ChevronsLeft } from 'lucide-react'
import { Avatar, AvatarFallback } from '../ui/avatar'
import ProfileMenu from './components/ProfileMenu'
import { useBaseStore } from '@/store'
import { authSliceStateSelector } from '@/store/authSlice'
import {
    appConfigSliceActionsSelector,
    appConfigSliceStateSelector,
} from '@/store/appConfigSlice'
import { TypographyP } from '../ui/typography'
import { cn } from '@/lib/utils'

const Header = () => {
    const { user } = useBaseStore(authSliceStateSelector)
    const { isSidenavOpen } = useBaseStore(appConfigSliceStateSelector)
    const { setSidenavOpen } = useBaseStore(appConfigSliceActionsSelector)

    return (
        <div className="flex items-center justify-between w-full px-4 bg-white border-b dark:bg-black h-header">
            <div className="flex items-center gap-4">
                <button
                    onClick={() => setSidenavOpen(!isSidenavOpen)}
                    className="relative group w-4 h-4"
                >
                    <ChevronsLeft
                        size={18}
                        className={cn(
                            'absolute transition ease-in duration-400 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2',
                            isSidenavOpen ? 'opacity-100' : 'opacity-0'
                        )}
                    />
                    <ChevronsRight
                        size={18}
                        className={cn(
                            'absolute transition ease-in duration-400 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2',
                            isSidenavOpen
                                ? 'opacity-0'
                                : 'opacity-0 group-hover:opacity-100'
                        )}
                    />
                    <Menu
                        size={18}
                        className={cn(
                            'absolute transition ease-in duration-400 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2',
                            isSidenavOpen
                                ? 'opacity-0'
                                : 'group-hover:opacity-0 opacity-100'
                        )}
                    />
                </button>
            </div>
            <div className="flex items-center h-full gap-4 ml-auto">
                <ProfileMenu
                    className="h-full px-4 hover:bg-accent"
                    trigger={
                        <div className="flex items-center h-full gap-2 pointer-events-none">
                            <Avatar className="w-6 h-6 text-xs">
                                <AvatarFallback className="bg-primary-500 text-gray-50">
                                    {getInitials(user || '')}
                                </AvatarFallback>
                            </Avatar>
                            <TypographyP className="hidden text-sm pointer-events-none sm:block">
                                {user}
                            </TypographyP>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default Header

import {
    TypographyH1,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import { useSystemQuery } from '@/queries/useSystemsQuery'
import { AnimatePresence, motion } from 'framer-motion'
import CardsSkeleton from './components/CardsSkeleton'
import SystemCard from './components/SystemCard'
import { Search } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { System } from '@/api/business/systems/types'
import { DebouncedInput } from '@/components/DebouncedInput'

const MainContent = () => {
    const { data, isPending, isError } = useSystemQuery()

    const [inputValue, setInputValue] = useState('')
    const [filteredData, setFilteredData] = useState<System[]>(data || [])

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    }

    const onFilter = (data: System[], value: string) => {
        return data.filter((system) => {
            return (
                system.name.toLowerCase().includes(value.toLowerCase()) ||
                system.description.toLowerCase().includes(value.toLowerCase())
            )
        })
    }

    const onInputChange = (value: string) => {
        setInputValue(value)
        if (data) setFilteredData(onFilter(data, value))
    }

    const baccessSystems = useMemo(
        () => filteredData.filter((item) => item.authBaccess),
        [filteredData]
    )
    const otherSystems = useMemo(
        () => filteredData.filter((item) => !item.authBaccess),
        [filteredData]
    )

    useEffect(() => {
        if (data) {
            setFilteredData(onFilter(data, inputValue))
        }
    }, [data])

    return (
        <div className="flex-1 px-4 py-10 space-y-8">
            <div className="flex flex-col items-center space-y-2">
                <TypographyH1 className="text-5xl text-center" asChild>
                    <motion.p
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        transition={{
                            duration: 0.2,
                            ease: 'easeOut',
                        }}
                    >
                        B/ACCESS
                    </motion.p>
                </TypographyH1>
                <TypographyMuted className="text-center" asChild>
                    <motion.p
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        transition={{
                            delay: 0.015,
                            duration: 0.2,
                            ease: 'easeOut',
                        }}
                    >
                        Gerenciamento de usuários e sistemas
                    </motion.p>
                </TypographyMuted>
            </div>
            <div>
                <div className="max-w-[680px] mx-auto rounded-full border shadow flex items-center gap-2 px-4 py-2">
                    <Search size={20} className="text-primary" />
                    <DebouncedInput
                        className="border-none shadow-none outline-none focus-visible:ring-0"
                        placeholder="Buscar por sistema"
                        onChange={(value) => onInputChange(value as string)}
                        value={inputValue}
                    />
                </div>
            </div>
            {data && (
                <>
                    {filteredData.length === 0 && data.length > 0 ? (
                        <div>
                            <TypographyMuted className="text-center">
                                Nenhum sistema encontrado
                            </TypographyMuted>
                        </div>
                    ) : null}
                </>
            )}
            {isPending || isError ? (
                <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-6">
                    <CardsSkeleton />
                </div>
            ) : null}

            {baccessSystems.length > 0 && (
                <div className="space-y-4">
                    <TypographyP className="font-semibold">
                        Sistemas gerenciados pelo B/ACCESS
                    </TypographyP>
                    <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-6">
                        <AnimatePresence>
                            {baccessSystems.map((system) => (
                                <SystemCard key={system.systemId} {...system} />
                            ))}
                        </AnimatePresence>
                    </div>
                </div>
            )}
            {otherSystems.length > 0 && (
                <div className="space-y-4">
                    <TypographyP className="font-semibold">
                        Outros sistemas
                    </TypographyP>
                    <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-6">
                        <AnimatePresence>
                            {otherSystems.map((system) => (
                                <SystemCard key={system.systemId} {...system} />
                            ))}
                        </AnimatePresence>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MainContent

import { isAxiosError } from '@/api/business'
import Image from '@/components/Image'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import {
    TypographyMuted,
    TypographyP,
    TypographySmall,
} from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { useUserSystemsQuery } from '@/queries/useUserSystemsQuery'
import getAppIcon from '@/utils/getAppIcon'
import { Check } from 'lucide-react'
import { ReactNode } from 'react'

type UserSystemsProps = {
    userId: number | null
    systemsSelection: Record<string, boolean>
    disabled: boolean
    setSystemsSelection: (selection: Record<string, boolean>) => void
    onClose: () => void
    onReplicate: () => void
}

type ContainerProps = {
    children: ReactNode
    disabled: boolean
    className?: string
    onClose: () => void
    onReplicate: () => void
}

const Container = ({
    children,
    className,
    disabled,
    onClose,
    onReplicate,
}: ContainerProps) => {
    return (
        <div className="flex flex-col flex-1 space-y-4 overflow-hidden">
            <div>
                <TypographyP className="font-semibold">
                    Sistemas do usuário
                </TypographyP>
                <TypographyMuted>
                    Selecione os sistemas abaixo para replicar o acesso
                </TypographyMuted>
            </div>
            <div className={cn('flex-1', className)}>{children}</div>
            <div className="flex items-center justify-end gap-2">
                <Button variant="ghost" onClick={onClose}>
                    Cancelar
                </Button>
                <Button onClick={onReplicate} disabled={disabled}>
                    Replicar
                </Button>
            </div>
        </div>
    )
}

const UserSystems = ({
    userId,
    systemsSelection,
    disabled,
    onClose,
    onReplicate,
    setSystemsSelection,
}: UserSystemsProps) => {
    const {
        data: systems = [],
        isPending,
        isError,
        error,
    } = useUserSystemsQuery({
        userId: userId!,
        enabled: !!userId,
    })

    const getIsChecked = (id: string) => systemsSelection[id]

    const onCheckChange = (id: string) => {
        setSystemsSelection({
            ...systemsSelection,
            [id]: !getIsChecked(id),
        })
    }

    if (!userId)
        return (
            <Container disabled onReplicate={onReplicate} onClose={onClose}>
                <TypographyMuted>Nenhum usuário selecionado</TypographyMuted>
            </Container>
        )

    if (isPending)
        return (
            <Container
                disabled
                onReplicate={onReplicate}
                className="space-y-2"
                onClose={onClose}
            >
                <Skeleton className="w-full h-8" />
                <Skeleton className="w-full h-8" />
                <Skeleton className="w-full h-8" />
            </Container>
        )

    if (isError) {
        let errorMessage = 'Houve um erro ao listar sistemas'

        if (isAxiosError(error)) {
            errorMessage = error.response?.data.error || errorMessage
        }

        return (
            <Container disabled onReplicate={onReplicate} onClose={onClose}>
                <TypographyP className="text-center text-red-500">
                    {errorMessage}
                </TypographyP>
            </Container>
        )
    }

    if (systems.length === 0) {
        return (
            <Container disabled onReplicate={onReplicate} onClose={onClose}>
                <TypographyMuted>Nenhum sistema disponível</TypographyMuted>
            </Container>
        )
    }

    return (
        <Container
            className="overflow-auto"
            disabled={disabled}
            onReplicate={onReplicate}
            onClose={onClose}
        >
            {systems.map((system) => {
                const image = getAppIcon(system.name)
                const fallback = getAppIcon('default')
                const canReplicate = system.supportN1

                return (
                    <div
                        key={system.systemId}
                        className={cn(
                            'flex items-center gap-2 p-2 rounded-md',
                            canReplicate
                                ? 'opacity-100 cursor-pointer hover:bg-accent'
                                : 'opacity-50'
                        )}
                        onClick={() => {
                            if (canReplicate) {
                                onCheckChange(system.systemId.toString())
                            }
                        }}
                    >
                        <Check
                            size={14}
                            className={cn(
                                '',
                                getIsChecked(system.systemId.toString())
                                    ? 'opacity-100'
                                    : 'opacity-0'
                            )}
                        />
                        <Image
                            className="h-[28px]"
                            src={image || fallback}
                            fallbackSrc={fallback}
                        />
                        <TypographyP>{system.name}</TypographyP>
                        {!canReplicate && (
                            <TypographySmall className="ml-auto text-xs text-destructive">
                                Não replicável
                            </TypographySmall>
                        )}
                    </div>
                )
            })}
        </Container>
    )
}

export default UserSystems
